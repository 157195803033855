<template>
  <div class="farm-page">
    <x-table :no-data-text="CA('farm_check') ? '暂无数据' : '暂无数据查询权限'" :columns="table.columns" :data="table.data" :loading="table.loading" :config="config" @add="add" @search="search" @page-change="pageChange" @page-size-change="pageSizeChange" :showSummary="true" :summaryMethod="handleSummary"></x-table>

    <Modal v-model="modal.show" :title="modal.title" @on-visible-change="modalShow" :width="900" fullscreen>
      <Form ref="form" :model="form" :rules="rules" :label-width="180">
        <Row>
          <Col span="8">
          <FormItem label="养殖企业" prop="name">
            <Input v-model="form.name" placeholder="请选择养殖企业" style="width: 260px" @on-focus="selFarm"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖场名称" prop="name">
            <Input clearable v-model="form.name" style="width: 260px" placeholder="请输入养殖场名称"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖场面积" prop="area">
            <Input clearable v-model="form.area" style="width: 260px" placeholder="请输入养殖场面积">
            <span slot="append">㎡</span>
            </Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="负责人电话" prop="chargePersonTel">
            <Input clearable v-model="form.chargePersonTel" style="width: 260px" placeholder="请输入负责人电话"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="区域" prop="region">
            <Cascader :data="areaList" v-model="form.region" change-on-select :load-data="loadData" style="width: 260px" placeholder="请选择区域" @on-change="handleCascader"></Cascader>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖场绘制" prop="mapPosition">
            <Input v-model="form.mapPosition" style="width: 260px" placeholder="请选择养殖场">
            <span slot="append" class="iconfont" style="cursor: pointer" @click="drawMap">&#xe648;</span>
            </Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="详细地址" prop="address">
            <Input clearable v-model="form.address" style="width: 260px" placeholder="请输入详细地址"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="粪污收集工艺" prop="manureProcess">
            <Select v-model="form.manureProcess" style="width: 260px" placeholder="请选择粪污收集工艺">
              <Option v-for="item in manureProcess" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="处理方式" prop="useTypes">
            <Select v-model="form.useTypes" multiple style="width: 260px">
              <Option v-for="item in dealMethod" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="产能配套率" prop="capacityMatchRate">
            <Input clearable v-model="form.capacityMatchRate" style="width: 260px" placeholder="请输入产能配套率"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="消纳规模" prop="consumptionScale">
            <Input clearable v-model="form.consumptionScale" style="width: 260px" placeholder="请输入消纳规模"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="建厂时间" prop="createDate">
            <DatePicker type="date" style="width: 260px" placeholder="请选择建厂时间" v-model="form.createDate"></DatePicker>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="养殖场类型">
            <Select clearable v-model="form.farmType" style="width: 260px" placeholder="请选择养殖场类型">
              <Option :value="1">普通场</Option>
              <Option :value="2">大型场</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="主要养殖品种">
            <Select clearable v-model="form.breedings" style="width: 260px" placeholder="请选择主要养殖品种 ">
              <Option :value="item.id + '/' + item.name" v-for="(item, index) in proList" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="畜禽圈舍建筑面积">
            <Input clearable v-model="form.housingArea" placeholder="请输入畜禽圈舍建筑面积" style="width: 260px">
            <span slot="append">m²</span>
            </Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="是否环保设备设施">
            <RadioGroup v-model="form.environmentallyOk">
              <Radio :label="1">
                <span>是</span>
              </Radio>
              <Radio :label="0">
                <span>否</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="粪污治理设施是否配套">
            <RadioGroup v-model="form.managementFacilitiesOk">
              <Radio :label="1">
                <span>是</span>
              </Radio>
              <Radio :label="0">
                <span>否</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="设计年出栏量" prop="ceilingCount">
            <Input clearable v-model="form.ceilingCount" style="width: 260px" placeholder="请输入设计年出栏量"></Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="设计存栏量" prop="designNum">
            <Input clearable v-model="form.designNum" style="width: 260px" placeholder="请输入设计存栏量"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="养殖区域类型">
            <Select clearable v-model="form.areaType" style="width: 260px" placeholder="请选择养殖区域类型 ">
              <Option :value="item.id" v-for="(item, index) in areaType" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="离河库最近距离">
            <Input clearable style="width: 260px" v-model="form.riverMeter" placeholder="请输入离河库最近距离">
            <span slot="append">米</span>
            </Input>
          </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="8">
          <FormItem label="是否雨污分流">
            <RadioGroup v-model="form.rainSewageDiversion">
              <Radio :label="1">
                <span>是</span>
              </Radio>
              <Radio :label="0">
                <span>否</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="是否有沼气池">
            <RadioGroup v-model="form.biogas">
              <Radio :label="1">
                <span>是</span>
              </Radio>
              <Radio :label="0">
                <span>否</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="固液分离">
            <RadioGroup v-model="form.solidLiquidSeparation">
              <Radio :label="1">
                <span>是</span>
              </Radio>
              <Radio :label="0">
                <span>否</span>
              </Radio>
            </RadioGroup>
          </FormItem>
          </Col>
          <!-- <Col span="8">
          <FormItem label="养殖方式">
            <Select clearable v-model="form.breedingType" style="width: 260px" placeholder="请选择养殖方式 ">
              <Option :value="item.id" v-for="(item, index) in breedingTypeList" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col> -->
        </Row>
        <!-- <Row>
          <Col span="8">
          <FormItem label="粪便处理利用模式">
            <Select clearable v-model="form.mowtau" style="width: 260px" placeholder="请选择粪便处理利用模式">
              <Option :value="item.id" v-for="(item, index) in mowtauList" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="污水利用方式">
            <Select clearable v-model="form.sewageUtilizationType" style="width: 260px" placeholder="请选择污水利用方式">
              <Option :value="item.id" v-for="(item, index) in sewageUtilizationTypeList" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
        </Row> -->
        <Row>
          <Col span="8">
          <FormItem label="负责人" prop="chargePerson">
            <Input clearable v-model="form.chargePerson" style="width: 260px" placeholder="请输入负责人"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="粪污处理流程">
            <Select clearable v-model="form.craft" style="width: 260px" placeholder="请选择粪污处理流程">
              <Option v-for="item in manureDealProcess" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="干粪台">
            <Input clearable style="width: 260px" v-model="form.dungDryingTable" placeholder="请输入干粪台面积">
            <span slot="append">平方米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="还田管网">
            <Input clearable style="width: 260px" v-model="form.returningPipe" placeholder="请输入还田管网长度">
            <span slot="append">米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="液体粪污贮存发酵设施容积">
            <Input clearable style="width: 260px" v-model="form.liquidStoragePool" placeholder="请输入液体粪污贮存发酵设施容积">
            <span slot="append">立方米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="沼液贮存设施容积">
            <Input clearable style="width: 260px" v-model="form.biogasPool" placeholder="请输入沼液贮存设施容积">
            <span slot="append">立方米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="发酵床容积">
            <Input clearable style="width: 260px" v-model="form.fermentationBed" placeholder="请输入发酵床容积">
            <span slot="append">立方米</span>
            </Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="备注" prop="remark">
            <Input clearable v-model="form.remark" style="width: 260px" placeholder="请输入备注"></Input>
          </FormItem>
          </Col>
          <Col span="8">
          <FormItem label="上传图片">
            <upload-image v-model="form.imgUrl" :max="1" :clear-flag="!modal.show"></upload-image>
          </FormItem>
          </Col>
        </Row>
        <div v-show="form.craft" style="
            width: 100%;
            height: 150px;
            border: 1px dashed #ccc;
            margin-bottom: 24px;
          " id="svga"></div>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit">提交</Button>
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <draw-map-modal v-model="drawMapModalShow" @draw-finish="drawFinish" :mapPosition="form.mapPosition"></draw-map-modal>

    <Modal v-model="dealModal" title="粪污处理方式" @on-visible-change="dealModalShow">
      <Form ref="dealForm" :model="dealForm" :rules="dealRules" :label-width="100">
        <FormItem label="处理方式" prop="useType">
          <Select v-model="dealForm.useType" multiple>
            <Option v-for="item in dealMethod" :value="item.id" :key="item.id">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="dealLoading" @click="dealSubmit">提交</Button>
        <Button @click="() => (dealModal = false)">取消</Button>
      </p>
    </Modal>
    <loh-information v-model="lohInformationShow" :farm="farm"></loh-information>
    <Modal v-model="editorModal" title="基地信息配置" :width="900" @on-visible-change="editorModalShow">
      <editor ref="detail" @editorContent="getEditorContent" v-model="remark" v-if="editorShow"></editor>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="editorLoading" @click="infoSubmit">提交</Button>
        <Button @click="() => (editorModal = false)">取消</Button>
      </p>
    </Modal>
    <Modal v-model="farmBlueprintShow" fullscreen>
      <farmBlueprint></farmBlueprint>
    </Modal>

    <picture-view v-model="picture.show" :src="picture.src"></picture-view>
    <select-farm-list v-model="selModal" :farmId="farmId" :options="options" @sel-finish="selFinish"></select-farm-list>
  </div>
</template>

<script>
import drawMapModal from './drawMapModal'
import CU from '@/common/util'
import lohInformation from '../lohInformation'
import editor from '@/components/editor'
import pictureView from '@/components/picture_view'
import * as SVGA from 'svgaplayerweb'
import farmBlueprint from '../../farmBlueprint/index.vue'
import selectFarmList from '@/components/selectFarm/selectFarmList'

export default {
  name: '',
  components: {
    drawMapModal,
    lohInformation,
    editor,
    pictureView,
    farmBlueprint,
    selectFarmList,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: '养殖场图片',
            width: 110,
            key: '',
            render: (h, { row }) => {
              if (!row.imgUrl) return <span>--</span>
              return (
                <img
                  src={this.getImgUrl(row.servicePath, row.imgUrl)}
                  onClick={() => this.lookPicture(row.servicePath + row.imgUrl)}
                  style="width: 35px;height: 35px;cursor:pointer;"
                />
              )
            },
          },
          {
            title: '养殖场名称',
            key: 'name',
            minWidth: 150,
          },
          {
            title: '规模类型',
            key: 'scaleInfoName',
            minWidth: 150,
          },
          {
            title: '镇街',
            key: 'regionCodeName',
            minWidth: 150,
          },
          {
            title: '养殖用途',
            key: 'applicationName',
            minWidth: 150,
          },
          {
            title: '当前存栏',
            key: 'currentNum',
            minWidth: 150,
          },
          {
            title: '能繁母猪',
            key: 'pigNum',
            minWidth: 150,
          },
          {
            title: '年出栏量',
            width: 100,
            key: 'ceilingCount',
          },
          {
            title: '污粪利用率',
            key: 'zRate',
            minWidth: 150,
            render: (h, { row }) => {
              return <span>{row.zRate + '%'}</span>
            },
          },
          {
            title: '存储量是否超过最大值',
            minWidth: 200,
            key: 'isStorageMax',
            render: (h, { row }) => {
              return <span>{row.isStorageMax == 1 ? '是' : '否'}</span>
            },
          },
          {
            title: '所需设施配置是否低于配置值数据',
            minWidth: 280,
            key: 'isFacilitiesMin',
            render: (h, { row }) => {
              return <span>{row.isFacilitiesMin == 1 ? '是' : '否'}</span>
            },
          },
          // {
          //   title: '粪污处理方式',
          //   minWidth: 120,
          //   key: 'useTypeName',
          // },
          {
            title: '收集工艺',
            width: 100,
            key: 'manureProcessName',
          },
          {
            title: '养殖场面积',
            width: 110,
            key: 'area',
            render: (h, { row }) => {
              return <span>{row.area + '㎡'}</span>
            },
          },
          {
            title: '负责人',
            width: 120,
            key: 'chargePerson',
          },
          {
            title: '负责人电话',
            width: 130,
            key: 'chargePersonTel',
          },
          {
            title: '创建时间',
            width: 180,
            key: 'createTime',
          },
          {
            title: '产能配套率',
            width: 120,
            key: 'capacityMatchRate',
          },
          {
            title: '消纳规模',
            width: 100,
            key: 'consumptionScale',
          },
          {
            title: '液体粪污贮存发酵设施容积(m³)',
            tooltip: true,
            key: 'liquidStoragePool',
            minWidth: 260,
          },
          {
            title: '沼液贮存设施容积(m³)',
            // width: 100,
            key: 'biogasPool',
            minWidth: 200,
          },
          {
            title: '发酵床容积（m³）',
            width: 180,
            key: 'fermentationBed',
          },
          {
            title: '备注',
            width: 180,
            key: 'remark',
          },
          {
            title: '操作',
            width: 260,
            fixed: 'right',
            render: (h, { row }) => {
              return (
                // {this.CA("farm_deal") && (
                //   <a
                //     style="margin-right: 10px"
                //     onClick={() => this.addDeal(row)}
                //   >
                //     粪污处理方式
                //   </a>
                // )}
                <div>
                  <a
                    style="margin-right:10px"
                    onClick={() => this.infoConfig(row)}
                  >
                    基地信息
                  </a>
                  <a
                    style="margin-right: 10px"
                    onClick={() => this.addFarmArea(row)}
                  >
                    养殖区
                  </a>
                  <a
                    style="margin-right: 10px"
                    onClick={() => this.farmBlueprint(row)}
                  >
                    图纸
                  </a>
                  {this.CA('farm_edit') && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA('farm_del') && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              )
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      modal: {
        show: false,
        title: '',
        submitLoading: false,
      },

      form: {
        id: '',
        name: '',
        area: '',
        chargePerson: '',
        chargePersonTel: '',
        imgUrl: '',
        address: '',
        siteType: '2',
        mapPosition: '',
        thirdLongLat: '',
        regionCode: '',
        region: [],
        manureProcess: '',
        craft: '',
        consumptionScale: '', //产能配套率
        capacityMatchRate: '', //消纳规模
        //畜禽代码
        livestockCode: '',
        //建厂时间自己选
        createDate: '',
        //养殖场类型(1.普通场 2.大型场)
        farmType: '',
        //畜禽种类
        species: '',
        //畜禽种类
        speciesName: '',
        //主要养殖品种
        breedings: '',
        breeding: '',
        //主要养殖品种
        breedingName: '',
        //畜禽圈舍建筑面积（平方米）
        housingArea: '',
        // 是否环保设备设施  （0:否，1:是）
        environmentallyOk: '',
        //粪污治理设施是否配套  （0:否，1:是）
        managementFacilitiesOk: '',
        //设计年出栏量 （头、羽、只）
        ceilingCount: '',
        //设计存栏量 （头、羽、只)
        designNum: '',
        //养殖区域类型 287 （1 限养，2 适养）
        areaType: '',
        //离河库最近距离(米)
        riverMeter: '',
        //是否雨污分流(0 ： 否，1 ：是)
        rainSewageDiversion: '',
        //是否有沼气池(0 ： 否，1 ：是)
        biogas: '',
        //养殖方式 494 1:自繁自养，2:仅育肥/商品畜禽饲养，3:仅饲养种畜禽，4：其他）
        breedingType: '',
        //粪便处理利用模式 477
        mowtau: '',
        // 固液分离（0:否，1:是）
        solidLiquidSeparation: '',
        //污水利用方式  487
        sewageUtilizationType: '',
        useTypes: '',
        useType: '',
        //干粪台
        dungDryingTable: '',
        //沼气池
        biogasPool: '',
        //储液池
        liquidStoragePool: '',
        //还田管网
        returningPipe: '',
        companyNo: '',
        fermentationBed: '',
        remark: '',
      },
      //主要养殖品种接口
      proList: [],
      selModal: false,
      options: {
        siteType: 2,
        //species: 3,
      },
      rules: {
        name: [{ required: true, message: '请填写基地名称' }],
        area: [{ required: true, message: '请填写养殖面积' }],
        chargePerson: [{ required: true, message: '请填写负责人' }],
        chargePersonTel: [
          { required: true, message: '请填写联系电话' },
          {
            validator(rule, value, callback) {
              if (CU.validatePhone(value)) {
                callback()
                return
              }
              if (CU.validateLandline(value)) {
                callback()
                return
              }
              callback(rule.message)
            },
            message: '手机号格式不正确',
            trigger: 'change',
          },
        ],
        address: [{ required: true, message: '请填写养殖地址' }],
        mapPosition: [{ required: true, message: '请绘制基地地图' }],
        region: [{ required: true, type: 'array', message: '请选择区域' }],
        manureProcess: [{ required: true, message: '请选择粪污类型' }],
        capacityMatchRate: [{ required: true, message: '请填写产能配套率' }],
        consumptionScale: [{ required: true, message: '请填写消纳规模' }],
        useTypes: [{ required: true, message: '请选择粪污处理方式' }],
      },
      drawMapModalShow: false,

      dealModal: false,
      dealForm: {
        farmId: '',
        siteType: '2',
        useType: [],
      },
      dealRules: {
        useType: [{ required: true, type: 'array', message: '请选择处理方式' }],
      },
      dealMethod: [],
      dealLoading: false,
      search_data: {},
      manureProcess: [],
      // 粪污处理工艺流程
      manureDealProcess: [],
      svgaPlayer: null,
      lohInformationShow: false,
      farm: {},
      //区域列表
      areaList: [],
      //区域类型列表
      areaType: [],
      //养殖方式
      breedingTypeList: [],
      //粪便处理利用模式
      mowtauList: [],
      //污水利用方式
      sewageUtilizationTypeList: [],
      editorModal: false,
      editorShow: false,
      remark: '',
      farmId: '',
      editorLoading: false,
      picture: {
        show: false,
        src: '',
      },
      farmBlueprintShow: false,
    }
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: '新增养殖场',
            ca: 'farm_add',
          },
          width: 220,
          filterBox: [
            {
              conditionName: '养殖场名称',
              component: 'input',
              field: 'name',
              defaultValue: '',
            },
            {
              conditionName: '粪污处理方式',
              component: 'select',
              field: 'useType',
              defaultValue: '',
              data: this.dealMethod,
              parameterField: 'id',
              showField: 'name',
              clearable: true,
            },
            {
              conditionName: '收集工艺',
              component: 'select',
              field: 'manureProcess',
              defaultValue: '',
              data: this.manureProcess,
              parameterField: 'id',
              showField: 'name',
              clearable: true,
            },
            {
              conditionName: '负责人',
              component: 'input',
              field: 'chargePerson',
              defaultValue: '',
            },
            {
              conditionName: '联系电话',
              component: 'input',
              field: 'chargePersonTel',
              defaultValue: '',
            },
          ],
        },
        page: { ...this.page },
      }
      return config
    },
  },
  watch: {
    'form.craft': {
      handler(val) {
        if (!val) return
        let item = this.manureDealProcess.find((item) => item.id == val)
        if (!item) return
        let url = item.description
        if (!this.svgaPlayer) this.svgaPlayer = new SVGA.Player('#svga')
        let parser = new SVGA.Parser('#svga')
        parser.load(url, (videoItem) => {
          this.svgaPlayer.setVideoItem(videoItem)
          this.svgaPlayer.startAnimation()
        })
      },
    },
  },
  methods: {
    selFarm() {
      this.selModal = true
    },
    selFinish(farm) {
      this.form.companyNo = farm.companyNo
      this.form.name = farm.farmerName
      this.selModal = false
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo
      this.getList()
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    search(data) {
      this.search_data = data
      this.page.pageNo = 1
      this.getList()
    },

    add() {
      this.modal = {
        show: true,
        title: '新增养殖场',
        submitLoading: false,
      }
    },
    drawMap() {
      this.drawMapModalShow = true
    },
    drawFinish(data) {
      this.form.mapPosition = JSON.stringify(data)
      let center = data.length > 0 ? JSON.parse(data[0].options.center) : {}
      this.form.thirdLongLat = center.lng + ',' + center.lat
      this.drawMapModalShow = false
    },

    getList() {
      if (!this.CA('farm_check')) return
      this.table.loading = true
      this.$post(this.$api.BASE_MANAGE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        siteType: '2',
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list
          this.page.total = +res.total
        })
        .finally(() => {
          this.table.loading = false
        })
    },
    getManureProcess() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '25',
      }).then((res) => {
        this.manureProcess = res.list
      })
    },
    getPro() {
      this.$post(this.$api.REGISTER.PRO, {
        type: '2',
      }).then((rs) => {
        this.proList = rs.list
      })
    },
    // 获取粪污处理流程
    getManureDealProcess() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '261',
      }).then((res) => {
        this.manureDealProcess = res.list
      })
    },
    addFarmArea(row) {
      this.lohInformationShow = true
      this.farm = row
    },
    farmBlueprint(row) {
      this.farmBlueprintShow = true
    },
    edit(row) {
      console.log(row)
      for (let key in this.form) {
        this.form[key] = row[key]
      }
      this.form.imgUrl = this.getImgUrl(row.servicePath, row.imgUrl)
      this.form.region =
        row.regionCode.indexOf(',') != -1
          ? row.regionCode.split(',')
          : [row.regionCode]
      if (row.useType) {
        this.form.useTypes = row.useType.split(',')
      }
      this.form.breedings = row.breeding + '/' + row.breedingName
      this.modal = {
        show: true,
        title: '编辑养殖场',
        submitLoading: false,
      }
    },
    infoConfig(row) {
      this.farmId = row.id
      this.remark = row.remark
      this.editorModal = true
      this.editorShow = true
    },
    getEditorContent(content) {
      this.remark = content
    },
    editorModalShow(visible) {
      if (visible) return
      this.$refs.detail.editor.txt.html('')
      this.editorShow = false
    },

    infoSubmit() {
      this.editorLoading = true
      this.$post(this.$api.BASE_MANAGE.EDIT, {
        id: this.farmId,
        remark: this.remark,
      })
        .then(() => {
          this.getList()
          this.editorModal = false
        })
        .finally(() => {
          this.editorLoading = false
        })
    },
    del(id) {
      this.$post(this.$api.BASE_MANAGE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success('删除成功')
        this.getList()
      })
    },

    //获取区域列表
    getAreaList() {
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: 100000,
      }).then((res) => {
        res.forEach((item) => {
          item.label = item.name
          item.value = item.id
          item.loading = false
          item.children = []
        })
        this.areaList = res
      })
    },
    //获取养殖区域类型
    getAreaType() {
      this.$post(this.$api.REGISTER.SCOPE, {
        pid: 287,
      }).then((res) => {
        this.areaType = res.list
      })
    },
    getbreedingType() {
      this.$post(this.$api.REGISTER.SCOPE, {
        pid: 494,
      }).then((res) => {
        this.breedingTypeList = res.list
      })
    },
    getmowtau() {
      this.$post(this.$api.REGISTER.SCOPE, {
        pid: 477,
      }).then((res) => {
        this.mowtauList = res.list
      })
    },
    getsewageUtilizationType() {
      this.$post(this.$api.REGISTER.SCOPE, {
        pid: 487,
      }).then((res) => {
        this.sewageUtilizationTypeList = res.list
      })
    },
    handleCascader(v, o) {
      console.log(v, o)
    },
    loadData(item) {
      item.loading = true
      this.$post(this.$api.AREA_MANAGE.LIST, {
        parentId: item.id,
      })
        .then((res) => {
          res.forEach((item) => {
            item.label = item.name
            item.value = item.id
            if (item.hasChild == 'true') {
              item.loading = false
              item.children = []
            }
          })
          item.children = res
        })
        .finally(() => {
          item.loading = false
        })
    },
    modalShow(visible) {
      if (visible) return
      this.form = {
        id: '',
        name: '',
        area: '',
        chargePerson: '',
        chargePersonTel: '',
        imgUrl: '',
        address: '',
        siteType: '2',
        mapPosition: '',
        thirdLongLat: '',
        regionCode: '',
        manureProcess: '',
        craft: '',
        capacityMatchRate: '',
        consumptionScale: '',
        livestockCode: '',
        createDate: '',
        farmType: '',
        species: '',
        speciesName: '',
        breedings: '',
        breeding: '',
        breedingName: '',
        housingArea: '',
        environmentallyOk: '',
        managementFacilitiesOk: '',
        ceilingCount: '',
        designNum: '',
        areaType: '',
        riverMeter: '',
        rainSewageDiversion: '',
        biogas: '',
        breedingType: '',
        mowtau: '',
        solidLiquidSeparation: '',
        sewageUtilizationType: '',
        useTypes: '',
        useType: '',
        dungDryingTable: '',
        biogasPool: '',
        liquidStoragePool: '',
        returningPipe: '',
        companyNo: '',
        region: [],
        fermentationBed: '',
        remark: '',
      }
      this.$refs.form.resetFields()
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return
        let params = { ...this.form }
        this.form.imgUrl &&
          (params.imgUrl = 'image' + this.form.imgUrl.split('image')[1])
        params.regionCode = this.form.region.join(',')
        delete params.region
        if (this.form.breedings) {
          params.breeding = this.form.breedings.split('/')[0]
          params.breedingName = this.form.breedings.split('/')[1]
        }
        params.useType = this.form.useTypes.join(',')
        this.modal.submitLoading = true
        this.$post(
          params.id ? this.$api.BASE_MANAGE.UPDATE : this.$api.BASE_MANAGE.ADD,
          params
        )
          .then(() => {
            this.getList()
            this.modal.show = false
          })
          .finally(() => {
            this.modal.submitLoading = false
          })
      })
    },
    handleSummary({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '统计',
          }
          return
        }
        const values = data.map((item) => Number(item[key]))
        if (
          column.key == 'area' ||
          column.key == 'count' ||
          column.key == 'ceilingCount' ||
          column.key == 'consumptionScale'
        ) {
          if (!values.every((value) => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: v,
            }
          } else {
            sums[key] = {
              key,
              value: '',
            }
          }
        } else {
          sums[key] = {
            key,
            value: '',
          }
        }
      })
      return sums
    },
    getDealMethod() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: '20',
      }).then((res) => {
        this.dealMethod = res.list
      })
    },
    addDeal(row) {
      this.dealForm.farmId = row.id
      row.useType && (this.dealForm.useType = row.useType.split(','))
      this.dealModal = true
    },
    dealModalShow(visible) {
      if (visible) return
      this.dealForm = {
        farmId: '',
        siteType: '2',
        useType: [],
      }
      this.$refs.dealForm.resetFields()
    },
    dealSubmit() {
      this.$refs.dealForm.validate().then((res) => {
        if (!res) return
        let params = { ...this.dealForm }
        params.useType = this.dealForm.useType.join(',')
        this.dealLoading = true
        this.$post(this.$api.BASE_MANAGE.DEAL_METHOD, params)
          .then(() => {
            this.getList()
            this.dealModal = false
          })
          .finally(() => {
            this.dealLoading = false
          })
      })
    },

    lookPicture(url) {
      this.picture = {
        show: true,
        src: url,
      }
    },
  },
  mounted() {
    this.getPro()
    this.getList()
    this.getmowtau()
    this.getAreaType()
    this.getAreaList()
    this.getDealMethod()
    this.getbreedingType()
    this.getManureProcess()
    this.getManureDealProcess()
    this.getsewageUtilizationType()
  },
}
</script>

<style lang="less" scoped>
.farm-page {
  width: 100%;
  height: 100%;
}
</style>